import React, { useEffect, useState } from 'react';
import { Box, Collapse, TextField, Badge, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import { fDateTo } from '../../utils/formatTime';
import _, { values } from 'lodash';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

const Calendar = ({ sDate, eDate, drivers, onClick }) => {



    const [page, setPage] = useState(1)
    const [pageMax, setPageMax] = useState(1)
    const [initialdrivers, setInitialdrivers] = useState([])

    useEffect(() => {
        axios.get(`http://119.110.241.46:1880/booking/get_id_page_size?page=${page}&size=10&type=driver`)
            .then(res => {
                setInitialdrivers(res.data.data)
                setPageMax(res.data.pagesize)
            })
    }, [page])

    const handleEventClick = (event, t) => {
        onClick(event, t)
    };




    return (
        <TableContainer component={Paper}>
            <Table size="small" style={{ border: "none" }} >
                <TableHead>
                    <TableRow>
                        <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1 }}>รหัสพนักงาน</TableCell>
                        <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1 }}>ชื่อนามสกุล</TableCell>
                        <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1 }}>ตำแน่ง</TableCell>
                        <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1 }}>เลขที่ใบขับขี่</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        initialdrivers.map((driver, index) => {
                            return (
                                <TableRowCheck driver={driver} startDate={new Date(sDate)} endDate={new Date(eDate)} drivers={drivers} handleEventClick={handleEventClick} key={`W-${index}`} />
                            )
                        })
                    }
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Stack spacing={1} justifyContent="space-between" direction="row">
                                <Button
                                    onClick={() => {
                                        setPage(p => { return p - 1 })
                                    }}
                                    disabled={page === 1}
                                >{`<`}</Button>
                                <Button
                                    onClick={() => {
                                        setPage(p => { return p + 1 })
                                    }}
                                    disabled={page === pageMax}
                                >{`>`}</Button>
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer >
    );
};



const TableRowCheck = ({ driver, drivers, startDate, endDate, handleEventClick, days }) => {
    const [isDup, setIsDup] = useState(false)
    const [openRows, setOpenRows] = useState(false);
    const [details, setDetails] = useState([]);


    useEffect(() => {
        setOpenRows(false)
        axios.get(`http://119.110.241.46:1880/booking/reserve_duplicate?id=${driver.userid}&type=driver&datetime_start=${fDateTo(startDate, "yyyy-MM-dd HH:mm")}&datetime_end=${fDateTo(endDate, "yyyy-MM-dd HH:mm")}`)
            .then(res => {
                if (res.data.message) {
                    setIsDup(false)
                    return
                }
                setIsDup(res.data.length > 0)
            })
    }, [driver])

    const isdriverDup = isDup ? "#FF2E2E" : "#00FF00"
    const handleAccordionClick = (userid) => {
        if (!openRows) {
            getData(userid)
            return
        }
        setOpenRows(!openRows)
    }

    const getData = (userid) => {
        axios.get(`http://119.110.241.46:1880/booking/reserve?id=${userid}&type=driver&datetime_start=${fDateTo(startDate, "yyyy-MM-dd HH:mm:ss")}`)
            .then(res => {
                if (res.data.message === null) {
                    setDetails([])
                    return
                }
                setDetails(res.data)
                setOpenRows(!openRows)
            })
    }
    return (
        <>
            <TableRow
                onClick={() => {
                    if (isDup) {
                        handleAccordionClick(driver.userid)
                    } else {
                        handleEventClick(driver.userid, drivers?.filter(d => d === driver.userid).length > 0 ? "del" : "add")
                    }
                }}
                style={{ cursor: "pointer", height: "70px" }}
            >
                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1 }}>
                    <Badge sx={{
                        "& .MuiBadge-badge": {
                            backgroundColor: isdriverDup
                        }
                    }}
                        badgeContent=" " variant="dot"
                    >
                        <Box marginX={"5px"}>
                            {`${driver.userid}`}
                        </Box>
                    </Badge>
                </TableCell>
                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1 }}>{`${driver.fname_lname}`}</TableCell>
                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1 }}>{`${driver.position}`}</TableCell>
                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1 }}>{`${driver.idcard}`}</TableCell>
            </TableRow >
            {
                openRows && (
                    <>
                        <TableRow >
                            <TableCell colSpan={4}>
                                <ReactApexChart
                                    options={{
                                        chart: {
                                            type: 'rangeBar',
                                            height: `${90 * details.filter((item, index, self) =>
                                                index === self.findIndex((t) => t.booking_id === item.booking_id)
                                            ).length}px`,
                                            toolbar: { show: false },
                                            zoom: false
                                        },
                                        plotOptions: {
                                            bar: {
                                                horizontal: true,
                                                barHeight: '20px'
                                            }
                                        },
                                        xaxis: {
                                            type: "datetime"
                                        },
                                        stroke: {
                                            width: 1
                                        },
                                        fill: {
                                            type: 'solid',
                                            opacity: 0.6
                                        },
                                        legend: {
                                            position: 'top',
                                            horizontalAlign: 'left'
                                        }
                                    }}
                                    series={[
                                        {
                                            data: details.map((row, id) => {
                                                return {
                                                    x: row.booking_id,
                                                    y: [
                                                        new Date(fDateTo(row.datetime_start, "yyyy-MM-dd HH:mm")).getTime(),
                                                        new Date(fDateTo(row.datetime_end, "yyyy-MM-dd HH:mm")).getTime()
                                                    ]
                                                }
                                            })
                                        }
                                    ]} type="rangeBar" height={`${100 * details.filter((item, index, self) =>
                                        index === self.findIndex((t) => t.booking_id === item.booking_id)
                                    ).length}px`} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4} align="center" >
                                <Button variant="contained"
                                    onClick={() => {
                                        handleEventClick(driver.userid, drivers?.filter(d => d === driver.userid).length > 0 ? "del" : "add")
                                    }}
                                >ยืนยันที่จะใช้งาน</Button>
                            </TableCell>
                        </TableRow>
                    </>
                )
            }
        </>
    )
}

export default Calendar;
