import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';



import _ from 'lodash';
import CalendarDriverbydate from './CalendarDriverbydate';
import axios from 'axios';

const initialForm = {}
const initial = []

export default function DialogDriver({ onHandle, sDate, eDate, drivers }) {
    const [formState, setFormState] = useState(initialForm)
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setFormState(initial)
    }
    const checkundefined = (data) => {
        if (data === undefined) {
            return ""
        }
        return data
    }
    const handleSave = (driver, t) => {
        onHandle(driver, t)
        setOpen(false)
    }
    return (
        <Box>
            <Stack direction="row" justifyContent="space-between">
                <Box>
                    พนักงานขับรถ
                </Box>
                <Box>
                    <Button variant="outlined" size="small" color="error"
                        onClick={handleOpen}
                    >
                        <AddOutlinedIcon fontSize="small" />
                    </Button>
                </Box>
            </Stack>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>เลือกรถ</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <CalendarDriverbydate drivers={drivers} onClick={(driver, t) => handleSave(driver, t)} sDate={sDate} eDate={eDate} />
                </DialogContent >
                <DialogActions>
                </DialogActions>
            </Dialog >
        </Box >
    )
}