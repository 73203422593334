import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import React, { useEffect, useState } from 'react'
import { Box, Stepper, Step, StepLabel, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup';
import WorkTypeDialog from './WorkTypeDialog'
import { fDateTo } from '../../utils/formatTime'
import CustomerDialog from './CustomerDialog'
import DialogTrip from './DialogTrip'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import ButtonDialogWorkUpdate from './ButtonDialogWorkUpdate'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonDialogWork from './ButtonDialogWork'

export default function UpdateBooking({ userId }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id } = useParams()
  const navigate = useNavigate()

  const initialValues = {
    "booking_userid": userId,
    "type_group": "",
    "type_booking": "",
    "datetime_start": null,
    "datetime_end": null,
    "type_address": "",
    "prefix": "",
    "firstname": "",
    "lastname": "",
    "taxid": "",
    "branch": "",
    "address": "",
    "subdistrict": "",
    "district": "",
    "province": "",
    "postcode": "",
    "phone": "",
    "work_ref": "",
    "contact_prefix": "",
    "contact_firstname": "",
    "contact_lastname": "",
    "contact_phone": "",
    "contact_req": [],
    "contact_note": "",
    "status_payment": "",
    "status_trip": "",
    "trip_data": []
  }



  const handleClickVariant = ({ message, variant }) => {
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

  };



  const handleSnackWithAction = ({ message, onHandle }) => {
    closeSnackbar()
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: "center",
        vertical: 'bottom'
      },
      variant: 'default',
      // style: { bgcolor: "whitesmoke !important" },
      action: (key) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={1}>
              <Button
                size='small'
                color="error"
                variant="contained"
                onClick={() => {
                  closeSnackbar(key)
                  onHandle()
                }}
              >
                <CheckRoundedIcon fontSize="small" />
              </Button>
              <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                <CloseRoundedIcon fontSize="small" />
              </Button>
            </Stack>
          </React.Fragment>
        )
      }
    });
  };

  const FormSchema = Yup.object().shape({

  });
  const formik = useFormik({
    initialValues: {
      ...initialValues
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
    onSubmit: (values, { setValues }) => {
      axios.post(`http://119.110.241.46:1880/booking/book_h`, { ...values, contact_req: JSON.stringify(values.contact_req) })
        .then(res => {
          handleClickVariant({ message: "บันทึกข้อมูลสำเร็จ", variant: "success" })
          // funcGetBooking(res.data.booking_id)
          navigate(`../update/${res.data.booking_id}`)
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  });


  const funcGetBooking = (booking_id) => {
    axios.get(`http://119.110.241.46:1880/booking/book_h?booking_id=${booking_id}`)
      .then(res => {
        formik.setValues(res.data)
      })
      .catch(err => {
        console.log('err', err)
      })
  }


  const onChangeType = (d) => {
    formik.setValues({ ...formik.values, ...d })
  }
  const onChangeBookingDate = (name, value) => {
    formik.setFieldValue(name, value)
  }
  const onChangeCustomer = (d) => {
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ padding: "10px" }}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={0} alternativeLabel>
              <Step>
                <StepLabel>รับเรื่อง</StepLabel>
              </Step>
              <Step>
                <StepLabel>จัดรถ</StepLabel>
              </Step>
              <Step>
                <StepLabel>ยืนยัน</StepLabel>
              </Step>
              <Step>
                <StepLabel>วิ่งงาน</StepLabel>
              </Step>
              <Step>
                <StepLabel>ชำระเงิน</StepLabel>
              </Step>
            </Stepper>
          </Box>
          <Stack spacing={1}>
            <Box><Typography variant="h6" sx={{ textAlign: "center" }} >ใบจอง</Typography></Box>
            <Box>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={1} >
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={1} >
                        <Grid item xs={5} md={5}>
                          <TextField size="small" label='กลุ่มประเภท' fullWidth
                            // value={values.booking_type}
                            value={formik.values.type_group}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" >
                                  <WorkTypeDialog onHandle={(d) => { onChangeType(d) }} />
                                </InputAdornment>
                              )
                            }}
                            name='type_group'
                            error={Boolean(formik.touched.type_group && formik.errors.type_group)}
                            helperText={formik.touched.type_group && formik.errors.type_group}
                            {...formik.getFieldProps('type_group')}
                          />
                        </Grid>
                        <Grid item xs={7} md={7}>
                          <TextField size="small" label='ประเภทงาน'
                            name='type_booking'
                            {...formik.getFieldProps('type_booking')}
                            error={Boolean(formik.touched.type_booking && formik.errors.type_booking)}
                            helperText={formik.touched.type_booking && formik.errors.type_booking}
                            fullWidth
                            value={formik.values.type_booking}
                          />
                        </Grid>

                        <Grid item xs={6} md={6}>
                          <DesktopDatePicker
                            label="วันที่เริ่ม"
                            inputFormat="dd/MM/yyyy"
                            value={formik.values.datetime_start}
                            name='datetime_start'
                            onChange={(value) => { onChangeBookingDate("datetime_start", value) }}
                            renderInput={(params) => <TextField size="small" label='วันที่เริ่ม' name='datetime_start' fullWidth {...params} />}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <TimePicker
                            ampm={false}
                            label="เวลา"
                            // value={fDateTo(new Date(fDateTo(new Date(), "yyyy-MM-dd") + " " + values?.booking_start_time), "yyyy-MM-dd HH:mm")}
                            value={formik.values.datetime_start}
                            name='datetime_start'
                            onChange={(value) => { onChangeBookingDate("datetime_start", value, "HH:mm") }}
                            renderInput={(params) => <TextField size="small" label='รับเวลา' name='pickup_time' fullWidth {...params} />}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <DesktopDatePicker
                            label="วันที่สิ้นสุด"
                            inputFormat="dd/MM/yyyy"
                            value={formik.values.datetime_end}
                            name='datetime_end'
                            onChange={(value) => { onChangeBookingDate("datetime_end", value) }}
                            renderInput={(params) => <TextField size="small" label='วันที่สิ้นสุด' name='datetime_end' fullWidth {...params} />}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <TimePicker
                            ampm={false}
                            label="เวลา"
                            // value={fDateTo(new Date(fDateTo(new Date(), "yyyy-MM-dd") + " " + values?.booking_end_time), "yyyy-MM-dd HH:mm")}
                            value={formik.values.datetime_end}
                            name='datetime_end'
                            onChange={(value) => { onChangeBookingDate("datetime_end", value) }}
                            renderInput={(params) => <TextField size="small" label='รับเวลา' name='pickup_time' fullWidth {...params} />}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField size="small" label='คำนำหน้า' name='prefix' fullWidth
                            value={formik.values.prefix}
                            {...formik.getFieldProps("prefix")}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <TextField size="small" label='ชื่อ' fullWidth
                            // InputLabelProps={{ shrink: formCus.name ? true : false }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" >
                                  <CustomerDialog onHandle={(d) => { onChangeCustomer(d) }} />
                                </InputAdornment>
                              )
                            }}
                            value={formik.values.firstname}
                            name='firstname'
                            {...formik.getFieldProps("firstname")}
                            error={Boolean(formik.touched.firstname && formik.errors.firstname)}
                            helperText={formik.touched.firstname && formik.errors.firstname}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <TextField size="small" label='นามสกุล' fullWidth
                            value={formik.values.lastname}
                            name='lastname'
                            {...formik.getFieldProps("lastname")}
                            error={Boolean(formik.touched.lastname && formik.errors.lastname)}
                            helperText={formik.touched.lastname && formik.errors.lastname}
                          />
                        </Grid>
                      </Grid>
                      <Collapse in={true} timeout="auto" unmountOnExit style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <Grid container spacing={1} >
                          <Grid item xs={8} md={8}>
                            <TextField size="small" label='เลขที่ผู้เสียภาษี' name='taxid' fullWidth
                              value={formik.values.taxid !== null ? formik.values.taxid : ""}
                              {...formik.getFieldProps("taxid")}
                            />
                          </Grid>
                          <Grid item xs={4} md={4}>
                            <TextField size="small" label='สาขา' name='branch' fullWidth
                              value={formik.values.branch}
                              {...formik.getFieldProps("branch")}
                            />
                          </Grid>
                          <Grid item xs={9} md={9}>
                            <TextField size="small" label='ที่อยู่' name='address' fullWidth
                              value={formik.values.address}
                              {...formik.getFieldProps("address")}
                            />
                          </Grid>
                          <Grid item xs={3} md={3}>
                            <TextField size="small" label='ประเภทที่อยู่' name='address_type' fullWidth
                              value={formik.values.address_type}
                              {...formik.getFieldProps("address_type")}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField size="small" label='แขวง/ตำบล' name='subdistrict' fullWidth
                              value={formik.values.subdistrict}
                              {...formik.getFieldProps("subdistrict")}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField size="small" label='เขต/อำเภอ' name='district' fullWidth
                              value={formik.values.district}
                              {...formik.getFieldProps("district")}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField size="small" label='จังหวัด' name='province' fullWidth
                              value={formik.values.province}
                              {...formik.getFieldProps("province")}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField size="small" label='รหัสไปรษณีย์' name='postcode' fullWidth
                              value={formik.values.postcode}
                              {...formik.getFieldProps("postcode")}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField size="small" label='เบอร์ติดต่อ' name='phone' fullWidth
                              value={formik.values.phone}
                              {...formik.getFieldProps("phone")}
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={1} >
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='เลขที่อ้างอิง' name='work_ref' fullWidth
                            {...formik.getFieldProps('work_ref')}
                            value={formik.values.work_ref}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ marginBottom: "10px" }}>
                            <Typography>ผู้ติดต่อ</Typography>
                          </Box>
                          <Grid container spacing={1}>
                            <Grid item xs={2}>
                              <TextField size="small" label='คำนำหน้า' name='contact_prefix' fullWidth
                                {...formik.getFieldProps('contact_prefix')}
                                value={formik.values.contact_prefix}
                                error={Boolean(formik.touched.contact_prefix && formik.errors.contact_prefix)}
                                helperText={formik.touched.contact_prefix && formik.errors.contact_prefix}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField size="small" label='ชื่อ' name='contact_firstname' fullWidth
                                {...formik.getFieldProps('contact_firstname')}
                                value={formik.values.contact_firstname}
                                error={Boolean(formik.touched.contact_firstname && formik.errors.contact_firstname)}
                                helperText={formik.touched.contact_firstname && formik.errors.contact_firstname}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField size="small" label='นามสกุล' name='contact_lastname' fullWidth
                                {...formik.getFieldProps('contact_lastname')}
                                value={formik.values.contact_lastname}
                                error={Boolean(formik.touched.contact_lastname && formik.errors.contact_lastname)}
                                helperText={formik.touched.contact_lastname && formik.errors.contact_lastname}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='เบอร์โทร' name='contact_phone' fullWidth
                            {...formik.getFieldProps('contact_phone')}
                            value={formik.values.contact_phone}
                            error={Boolean(formik.touched.contact_phone && formik.errors.contact_phone)}
                            helperText={formik.touched.contact_phone && formik.errors.contact_phone}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Autocomplete
                            multiple
                            limitTags={10}
                            id="multiple-limit-tags"
                            value={formik.values.contact_req}
                            isOptionEqualToValue={(option, value) => option === value}
                            options={["น้ำดื่ม", "ผ้าเย็น", "ขนม", "ขนม1", "ขนม2", "ขนม3", "ขนม4"]}
                            getOptionLabel={(option) => option}
                            onChange={(event, newValue) => {
                              formik.setFieldValue("contact_req", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField size="small" label='รายละเอียดความต้องการ' {...params} sx={{ overFlow: "auto" }} fullWidth />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField size="small" label='หมายเหตุ' multiline maxRows={3} minRows={3}
                            value={formik.values.contact_note}
                            {...formik.getFieldProps('contact_note')}
                            name='contact_note' fullWidth />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Button
                            type="submit"
                            variant="outlined">บันทึก</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Box>
          </Stack>
        </Box>
      </LocalizationProvider>
    </div>
  )
}
