import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import FindInPageIcon from '@mui/icons-material/FindInPage';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogDriver from './DialogDriver';
import DialogReception from './DialogReception';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CarsDialog from './CarsDialog';
import { fDateTo, fDateZTo } from '../../utils/formatTime';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import * as Yup from 'yup';

export default function ButtonDialogWorkUpdate({ data, booking_id, trip_id, onHandleSubmit }) {
    const FormSchema = Yup.object().shape({

    });
    const formik = useFormik({
        initialValues: {
            ...data,
            booking_id: booking_id,
            trip_id: trip_id,
            station_start_datetime: fDateZTo(data.station_start_datetime, "yyyy-MM-dd HH:mm"),
            station_end_datetime: fDateZTo(data.station_end_datetime, "yyyy-MM-dd HH:mm"),
            station_start_time: fDateZTo(data.station_start_datetime, "yyyy-MM-dd HH:mm"),
            station_end_time: fDateZTo(data.station_end_datetime, "yyyy-MM-dd HH:mm")
        },
        validationSchema: FormSchema,
        enableReinitialize: true,
        onSubmit: (values, { setValues }) => {
            axios.put(`http://119.110.241.46:1880/booking/book_d`, {
                "booking_id": values.booking_id,
                "trip_id": values.trip_id,
                "line_id": values.line_id,
                "type_car": values.type_car,
                "vehicle_id": values.vehicle_id,
                "driver_id": values.driver_id,
                "reception_id": values.reception_id,
                "station_start": values.station_start,
                "station_start_datetime": `${fDateTo(values.station_start_datetime, "yyyy-MM-dd")} ${fDateTo(values.station_start_time, "HH:mm:ss")}`,
                "station_end_datetime": `${fDateTo(values.station_end_datetime, "yyyy-MM-dd")} ${fDateTo(values.station_end_time, "HH:mm:ss")}`,
                "station_start_flight": values.station_start_flight,
                "station_end": values.station_end,
                "station_end_flight": values.station_end_flight,
                "note": values.note
            })
                .then(res => {
                    handleClickVariant({ message: "บันทึกข้อมูลสำเร็จ", variant: "success" })
                    setOpen(false)
                    onHandleSubmit(booking_id)
                })
                .catch(err => {
                    console.log('err', err)
                })
        }
    });
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        formik.resetForm()
        setOpen(false)

    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = ({ message, variant }) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };

    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };

    const onChangeBookingDate = (name, value) => {
        formik.setFieldValue(name, value)
    }
    const handleChangeReception = (reception, type) => {
        if (type === "add") {
            const d = formik.values.reception_id.concat(reception)
            formik.setValues({ ...formik.values, reception_id: d })
        } else {
            const d = formik.values.reception_id.filter(v => v !== reception)
            formik.setValues({ ...formik.values, reception_id: d })
        }
    }
    const handleChangeCars = (car) => {
        formik.setValues({ ...formik.values, ...car })
    }
    const handleChangeDriver = (driver, type) => {

        if (type === "add") {
            const d = formik.values.driver_id.concat(driver)
            formik.setValues({ ...formik.values, driver_id: d })
        } else {
            const d = formik.values.driver_id.filter(v => v !== driver)
            formik.setValues({ ...formik.values, driver_id: d })
        }
    }

    return (
        <Box>
            <IconButton onClick={handleOpen} size="small"><FindInPageIcon fontSize="inherit" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>รายละเอียด ใบงาน</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack>
                                        <Grid container spacing={1} >

                                            <Grid item xs={12} md={12}>
                                                <Grid container spacing={1} >
                                                    <Grid item xs={12} md={12}>
                                                        <TextField size="small" label='ทะเบียนรถ' placeholder='ทะเบียนรถ'
                                                            {...formik.getFieldProps('vehicle_id')}
                                                            error={Boolean(formik.touched.vehicle_id && formik.errors.vehicle_id)}
                                                            helperText={formik.touched.vehicle_id && formik.errors.vehicle_id}
                                                            name='vehicle_id' fullWidth
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end" >
                                                                        <CarsDialog onHandle={(e) => handleChangeCars(e)} sDate={formik.values.station_start_datetime} eDate={formik.values.station_end_datetime} />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <TextField size="small" label='สถานที่รับ'
                                                    {...formik.getFieldProps('station_start')}
                                                    error={Boolean(formik.touched.station_start && formik.errors.station_start)}
                                                    helperText={formik.touched.station_start && formik.errors.station_start}
                                                    name='station_start'
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" onClick={() => {
                                                                }}><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <DesktopDatePicker
                                                    label="วันที่"
                                                    inputFormat="dd/MM/yyyy"
                                                    value={formik.values.station_start_datetime}
                                                    name='station_start_datetime'
                                                    onChange={(value) => { onChangeBookingDate("station_start_datetime", value) }}
                                                    renderInput={(params) => <TextField size="small" label='วันที่' name='station_start_datetime' fullWidth {...params} />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <TimePicker
                                                    ampm={false}
                                                    label="รับเวลา"
                                                    value={formik.values.station_start_time}
                                                    name='station_start_time'
                                                    onChange={(value) => { onChangeBookingDate("station_start_time", value) }}
                                                    renderInput={(params) => <TextField size="small" label='รับเวลา' name='station_start_time' fullWidth {...params} />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <TextField size="small" label='flight' placeholder='XX-XXXXX'
                                                    {...formik.getFieldProps('station_start_flight')}
                                                    error={Boolean(formik.touched.station_start_flight && formik.errors.station_start_flight)}
                                                    helperText={formik.touched.station_start_flight && formik.errors.station_start_flight}
                                                    name='station_start_flight' fullWidth />
                                            </Grid>

                                            <Grid item xs={3} md={3}>
                                                <TextField size="small" label='สถานที่ส่ง'
                                                    {...formik.getFieldProps('station_end')}
                                                    error={Boolean(formik.touched.station_end && formik.errors.station_end)}
                                                    helperText={formik.touched.station_end && formik.errors.station_end}
                                                    name='station_end'
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" onClick={() => {
                                                                }}><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <DesktopDatePicker
                                                    label="ส่งวันที่"
                                                    inputFormat="dd/MM/yyyy"
                                                    value={formik.values.station_end_datetime}
                                                    name='station_end_datetime'
                                                    onChange={(value) => { onChangeBookingDate("station_end_datetime", value) }}
                                                    renderInput={(params) => <TextField size="small" label='ส่งวันที่' name='station_end_datetime' fullWidth {...params} />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <TimePicker
                                                    ampm={false}
                                                    label="รับเวลา"
                                                    value={formik.values.station_end_time}
                                                    name='station_end_time'
                                                    onChange={(value) => { onChangeBookingDate("station_end_time", value) }}
                                                    renderInput={(params) => <TextField size="small" label='รับเวลา' name='station_end_time' fullWidth {...params} />}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <TextField size="small" label='flight'
                                                    {...formik.getFieldProps('station_end_flight')}
                                                    error={Boolean(formik.touched.station_end_flight && formik.errors.station_end_flight)}
                                                    helperText={formik.touched.station_end_flight && formik.errors.station_end_flight}
                                                    name='station_end_flight'
                                                    fullWidth />
                                            </Grid>

                                            {/* ************************พนักงานขับรถ************************ */}
                                            <Grid item xs={12} md={12}>
                                                <DialogDriver onHandle={(e, t) => handleChangeDriver(e, t)} drivers={formik.values.driver_id} sDate={`${fDateTo(formik.values.station_start_datetime, "yyyy-MM-dd")} ${fDateTo(formik.values.station_start_time, "HH:mm:ss")}`} eDate={`${fDateTo(formik.values.station_end_datetime, "yyyy-MM-dd")} ${fDateTo(formik.values.station_end_time, "HH:mm:ss")}`} />
                                            </Grid>
                                            {
                                                formik.values?.driver_id?.map((driver, index) => {
                                                    return (
                                                        <Grid item xs={12} md={12}>
                                                            <Stack direction="row" spacing={1}>
                                                                <TextField size="small" disabled label='userid' placeholder='userid' value={driver} fullWidth />
                                                                <Button onClick={() => handleChangeDriver(driver, "del")}>ลบ</Button>
                                                            </Stack>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            {/* ************************พนักงานขับรถ************************ */}
                                            {/* ************************พนักงานต้อนรับ************************ */}
                                            <Grid item xs={12} md={12}>
                                                <DialogReception onHandle={(e, t) => handleChangeReception(e, t)} receptions={formik.values.reception_id} sDate={`${fDateTo(formik.values.station_start_datetime, "yyyy-MM-dd")} ${fDateTo(formik.values.station_start_time, "HH:mm:ss")}`} eDate={`${fDateTo(formik.values.station_end_datetime, "yyyy-MM-dd")} ${fDateTo(formik.values.station_end_time, "HH:mm:ss")}`} />
                                            </Grid>
                                            {
                                                formik.values?.reception_id?.map((reception, index) => {
                                                    return (
                                                        <Grid item xs={12} md={12}>
                                                            <Stack direction="row" spacing={1}>
                                                                <TextField size="small" disabled label='userid' placeholder='userid' value={reception} fullWidth />
                                                                <Button onClick={() => handleChangeReception(reception, "del")}>ลบ</Button>
                                                            </Stack>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            {/* ************************พนักงานต้อนรับ************************ */}
                                            <Grid item xs={12} md={12}>
                                                <TextField size="small" label='หมายเหตุ' value={``} multiline maxRows={3} minRows={3} name='remake' fullWidth />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </DialogContent >
                <DialogActions>
                    <Button onClick={() => { formik.submitForm() }}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}
